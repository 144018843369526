import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from '@prlw/core/products/product.entity';

export const PRODUCTS_GATEWAY = new InjectionToken<ProductsGateway>(
  'ProductsGateway',
);

export interface ProductsGateway {
  getProductsOfGroup(
    productGroup: string,
    destination: string | null,
    shipments: string[],
  ): Observable<Product[]>;
}
