import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AllDeal } from '@prlw/core/deals/entities/all-deal.entity';
import { LastDeal } from '@prlw/core/deals/entities/last-deal.entity';
import { DealsFilterQuery } from '@prlw/core/deals/deals-filter.provider';

export const DEALS_GATEWAY = new InjectionToken<DealsGateway>('deals-gateway');

export interface DealsGateway {
  getAllDeals(
    sort: string,
    order: string,
    filters: DealsFilterQuery,
    search?: string | null,
    page?: string | null,
    pageSize?: string | null,
  ): Observable<AllDeal[]>;
  getDealsByInstrument(
    instrumentCode: string,
    sort: string,
    order: string,
  ): Observable<LastDeal[]>;
}
