import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageTitleService } from '@prlw/common/page/page-title.service';
import { ResponsiveService } from '@prlw/libs/responsive/responsive.service';

@Component({
  selector: 'prlw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  constructor(
    private readonly pageTitleService: PageTitleService,
    private readonly responsiveService: ResponsiveService,
  ) {
    pageTitleService.setPageTitle('');
  }

  xsmall$ = this.responsiveService.xsmall$;
}
