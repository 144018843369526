<div class="tabs">
  <div
    *ngFor="let tab of tabs"
    class="tab"
    [class.active]="tab === activeTab"
    (click)="setActiveTab(tab.id)"
  >
    {{ tab.label }}
  </div>
</div>
<div class="tab-content" *ngIf="activeTab">
  <ng-container *ngTemplateOutlet="activeTab.bodyContent"></ng-container>
</div>
