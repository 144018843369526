import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { TSpimexIndexesItem } from './types/spimex-indexes-item';
import { TProductPickerItem } from './types/product-picker-item';
import { TPriceComparisonItem } from './types/price-comparison-item';
import { TDynamicsOfVolumesItem } from './types/dynamics-of-volumes-item';
import {
  TObservableWithPending,
  observableWithPending,
  mapResult,
  asOwpSuccess,
  mapPending,
  mapError,
} from '@prlw/infrastructure/observableWithPending-v2';
import { AnalyticsChartsController } from './analytics-charts.controller';
import { TDynamicsByPlants } from './types/dynamics-by-plants';
import { TDynamicsByBidsItem } from './types/dynamics-by-bids-item';
import { TCargoFlowItem } from './types/cargo-flow-item';
import { TDynamicsOfProductionVolumesItem } from './types/dynamics-of-production-volumes-item';
import { TDynamicsOfProductionVolumesFourWeeksItem } from './types/dynamics-of-production-volumes-four-weeks-item';
import { TPeriodItem } from './types/period-item';
import { TIceFuturesItem } from './types/ice-futures-item';
import { Setting } from '../user-settings/setting.interface';
import { UserSettingsController } from '../user-settings/user-settings.controller';
import { getEndDate, getStartDate } from '@prlw/libs/date-periods';

const ANALYTICS_PRODUCT_PICKER_ITEMS_SETTING = new Setting<
  TProductPickerItem[]
>('analytics-product-picker-items');

@Injectable({
  providedIn: 'root',
})
export class AnalyticsChartsProvider implements OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  getEtipItems$ = new BehaviorSubject<
    TObservableWithPending<TSpimexIndexesItem[]>
  >(asOwpSuccess([]));
  etipItems$ = mapResult(this.getEtipItems$);
  etipItemsPending$ = mapPending(this.getEtipItems$);

  getEripItems$ = new BehaviorSubject<
    TObservableWithPending<TSpimexIndexesItem[]>
  >(asOwpSuccess([]));
  eripItems$ = mapResult(this.getEripItems$);
  eripItemsPending$ = mapPending(this.getEripItems$);

  getProductPickerItems$ = new BehaviorSubject<
    TObservableWithPending<TProductPickerItem[]>
  >(asOwpSuccess([]));
  productPickerItems$ = mapResult(this.getProductPickerItems$);
  productPickerItemsPending$ = mapPending(this.getProductPickerItems$);
  productPickerItemsError$ = mapError(this.getProductPickerItems$);

  getProductPickerItemsSingle$ = new BehaviorSubject<
    TObservableWithPending<TProductPickerItem[]>
  >(asOwpSuccess([]));
  productPickerItemsSingle$ = mapResult(this.getProductPickerItemsSingle$);

  getProductPickerCargoItems$ = new BehaviorSubject<
    TObservableWithPending<TProductPickerItem[]>
  >(asOwpSuccess([]));
  productPickerCargoItems$ = mapResult(this.getProductPickerCargoItems$);

  getPriceComparisonItems$ = new BehaviorSubject<
    TObservableWithPending<TPriceComparisonItem[]>
  >(asOwpSuccess([]));
  priceComparisonItems$ = mapResult(this.getPriceComparisonItems$);
  priceComparisonItemsPending$ = mapPending(this.getPriceComparisonItems$);

  getDynamicsOfVolumesItems$ = new BehaviorSubject<
    TObservableWithPending<TDynamicsOfVolumesItem[]>
  >(asOwpSuccess([]));
  dynamicsOfVolumesItems$ = mapResult(this.getDynamicsOfVolumesItems$);
  dynamicsOfVolumesItemsPending$ = mapPending(this.getDynamicsOfVolumesItems$);

  getDynamicsOfVolumesTwoWeeksItems$ = new BehaviorSubject<
    TObservableWithPending<TDynamicsOfVolumesItem[]>
  >(asOwpSuccess([]));
  dynamicsOfVolumesTwoWeeksItems$ = mapResult(
    this.getDynamicsOfVolumesTwoWeeksItems$,
  );
  dynamicsOfVolumesTwoWeeksItemsPending$ = mapPending(
    this.getDynamicsOfVolumesTwoWeeksItems$,
  );

  getDynamicsByPlantsItems$ = new BehaviorSubject<
    TObservableWithPending<TDynamicsByPlants>
  >(
    asOwpSuccess({
      dates: [],
      highestVolume: 0,
      table: [],
      totalVolumesByProductGroupName: [],
      unsoldVolumes: [],
    }),
  );
  dynamicsByPlantsItems$ = mapResult(this.getDynamicsByPlantsItems$);
  dynamicsByPlantsItemsPending$ = mapPending(this.getDynamicsByPlantsItems$);
  dynamicsByPlantsSessions$ = new BehaviorSubject({
    primary: true,
    secondary: true,
  });
  updateDynamicsByPlantsSessions(primary: boolean, secondary: boolean) {
    this.dynamicsByPlantsSessions$.next({
      primary,
      secondary,
    });
  }
  dynamicsByPlantsDeliveryMethods$ = new BehaviorSubject({
    f: true,
    w: true,
    u: true,
  });
  updateDynamicsByPlantsDeliveryMethods(f: boolean, w: boolean, u: boolean) {
    this.dynamicsByPlantsDeliveryMethods$.next({
      f,
      w,
      u,
    });
  }

  getDynamicsByBidsItems$ = new BehaviorSubject<
    TObservableWithPending<TDynamicsByBidsItem[]>
  >(asOwpSuccess([]));
  dynamicsByBidsItems$ = mapResult(this.getDynamicsByBidsItems$);
  dynamicsByBidsItemsPending$ = mapPending(this.getDynamicsByBidsItems$);
  dynamicsByBidsSessions$ = new BehaviorSubject({
    primary: true,
    secondary: true,
  });
  updateDynamicsByBidsSessions(primary: boolean, secondary: boolean) {
    this.dynamicsByBidsSessions$.next({
      primary,
      secondary,
    });
  }
  dynamicsByBidsDeliveryMethods$ = new BehaviorSubject({
    f: true,
    w: true,
    u: true,
  });
  updateDynamicsByBidsDeliveryMethods(f: boolean, w: boolean, u: boolean) {
    this.dynamicsByBidsDeliveryMethods$.next({
      f,
      w,
      u,
    });
  }

  getCargoFlowItems$ = new BehaviorSubject<
    TObservableWithPending<TCargoFlowItem[]>
  >(asOwpSuccess([]));
  cargoFlowItems$ = mapResult(this.getCargoFlowItems$);
  cargoFlowItemsPending$ = mapPending(this.getCargoFlowItems$);

  getIceFuturesItems$ = new BehaviorSubject<
    TObservableWithPending<TIceFuturesItem[]>
  >(asOwpSuccess([]));
  iceFuturesItems$ = mapResult(this.getIceFuturesItems$);
  iceFuturesItemsPending$ = mapPending(this.getIceFuturesItems$);

  getDynamicsOfProductionVolumesItems$ = new BehaviorSubject<
    TObservableWithPending<TDynamicsOfProductionVolumesItem[]>
  >(asOwpSuccess([]));
  dynamicsOfProductionVolumesItems$ = mapResult(
    this.getDynamicsOfProductionVolumesItems$,
  );
  dynamicsOfProductionVolumesItemsPending$ = mapPending(
    this.getDynamicsOfProductionVolumesItems$,
  );

  getDynamicsOfProductionVolumesFourWeeksItems$ = new BehaviorSubject<
    TObservableWithPending<TDynamicsOfProductionVolumesFourWeeksItem[]>
  >(asOwpSuccess([]));
  dynamicsOfProductionVolumesFourWeeksItems$ = mapResult(
    this.getDynamicsOfProductionVolumesFourWeeksItems$,
  );
  dynamicsOfProductionVolumesFourWeeksItemsPending$ = mapPending(
    this.getDynamicsOfProductionVolumesFourWeeksItems$,
  );

  updateProductPickerItems$ = new Subject<TProductPickerItem>();
  updateProductPickerItemsSingle$ = new Subject<TProductPickerItem>();
  updateProductPickerCargoItems$ = new Subject<TProductPickerItem>();

  getStartDate(date: Date) {
    return getStartDate(date);
  }

  getEndDate(date: Date) {
    return getEndDate(date);
  }

  iceFuturesProducts = [
    {
      product: 'Brent Crude Futures',
      productId: 254,
      hubId: 403,
      specId: 219,
    },
    {
      product: 'WTI Crude Futures',
      productId: 425,
      hubId: 619,
      specId: 213,
    },
    {
      product: 'Dubai 1st Line Futures',
      productId: 753,
      hubId: 832,
      specId: 6753497,
    },
    {
      product: 'Low Sulphur Gasoil Futures',
      productId: 5817,
      hubId: 9373,
      specId: 34361119,
    },
  ];
  iceFuturesProduct$ = new BehaviorSubject<{
    product: string;
    productId: number;
    hubId: number;
    specId: number;
  }>(this.iceFuturesProducts[0]);
  updateIceFuturesProduct(product: string) {
    const nextProduct = this.iceFuturesProducts.find(
      (item) => item.product === product,
    );
    if (nextProduct) {
      this.iceFuturesProduct$.next(nextProduct);
    }
  }
  iceFuturesCurrentItem$ = new BehaviorSubject<number>(0);
  updateIceFuturesCurrentItem(index: number) {
    this.iceFuturesCurrentItem$.next(index);
  }

  iceFuturesPeriodItems: TPeriodItem[] = [
    {
      label: 'intraday',
      startDate: 'intraday',
      endDate: '',
    },
    {
      label: '3 months',
      startDate: '1',
      endDate: '',
    },
    {
      label: '1 year',
      startDate: '2',
      endDate: '',
    },
    {
      label: '2 years',
      startDate: '3',
      endDate: '',
    },
  ];
  iceFuturesPeriodItem$ = new BehaviorSubject<TPeriodItem>(
    this.iceFuturesPeriodItems[0],
  );
  updateIceFuturesPeriodItem(item: TPeriodItem) {
    this.iceFuturesPeriodItem$.next(item);
  }

  periodItems: TPeriodItem[] = [
    {
      label: '1 мес',
      startDate: this.getStartDate(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 1),
      ),
      endDate: this.getEndDate(new Date(new Date().getTime())),
    },
    {
      label: '3 мес',
      startDate: this.getStartDate(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 3),
      ),
      endDate: this.getEndDate(new Date(new Date().getTime())),
    },
    {
      label: '6 мес',
      startDate: this.getStartDate(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 6),
      ),
      endDate: this.getEndDate(new Date(new Date().getTime())),
    },
    {
      label: '1 год',
      startDate: this.getStartDate(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 12),
      ),
      endDate: this.getEndDate(new Date(new Date().getTime())),
    },
    {
      label: '1 нед',
      startDate: this.getStartDate(
        new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
      ),
      endDate: this.getEndDate(new Date(new Date().getTime())),
    },
    ...[...Array(3)].map((_, index) => {
      const year = new Date().getFullYear() - 2 + index;
      return <TPeriodItem>{
        label: String(year),
        startDate: `${year}-01-01T00:00:00.000Z`,
        endDate: `${year}-12-31T23:59:59.000Z`,
      };
    }),
  ];

  customPeriodItem$ = new BehaviorSubject<TPeriodItem>({
    label: 'свой период',
    startDate: this.getStartDate(
      new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 30 * 1),
    ),
    endDate: this.getEndDate(new Date(new Date().getTime())),
  });
  updateCustomPeriodItem(item: TPeriodItem) {
    this.customPeriodItem$.next(item);
  }

  getCurrentDateInit = () => ({
    label: new Intl.DateTimeFormat('ru', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }).format(new Date()),
    startDate: this.getStartDate(new Date()),
    endDate: this.getEndDate(new Date()),
  });
  customDateItem$ = new BehaviorSubject<TPeriodItem>(this.getCurrentDateInit());
  updateCustomDateItem(item: TPeriodItem) {
    this.customDateItem$.next(item);
  }

  spimexIndexesPeriod$ = new BehaviorSubject<TPeriodItem>(this.periodItems[0]);
  dynamicsOfVolumesPeriod$ = new BehaviorSubject<TPeriodItem>(
    this.periodItems[7],
  );
  priceComparisonPeriod$ = new BehaviorSubject<TPeriodItem>(
    this.periodItems[0],
  );

  updateSpimexIndexexPeriod(item: TPeriodItem) {
    this.spimexIndexesPeriod$.next(item);
  }
  updateDynamicsOfVolumesPeriod(item: TPeriodItem) {
    this.dynamicsOfVolumesPeriod$.next(item);
  }
  updatePriceComparisonPeriod(item: TPeriodItem) {
    this.priceComparisonPeriod$.next(item);
  }

  productGroupNames$ = this.productPickerItems$.pipe(
    debounceTime(500),
    map((items) =>
      items.reduce((acc, { rows }) => {
        return [
          ...acc,
          ...rows.filter((row) => row.checked).map((row) => row.name),
        ];
      }, [] as string[]),
    ),
    filter((names) => !!names.length),
  );

  productGroupNamesSingle$ = this.productPickerItemsSingle$.pipe(
    debounceTime(500),
    map((items) =>
      items.reduce((acc, { rows }) => {
        return [
          ...acc,
          ...rows.filter((row) => row.checked).map((row) => row.name),
        ];
      }, [] as string[]),
    ),
    filter((names) => !!names.length),
  );

  productCargoGroupNames$ = this.productPickerCargoItems$.pipe(
    debounceTime(500),
    map((items) =>
      items.reduce((acc, { rows }) => {
        return [
          ...acc,
          ...rows.filter((row) => row.checked).map((row) => row.name),
        ];
      }, [] as string[]),
    ),
    filter((names) => !!names.length),
  );

  constructor(
    private readonly controller: AnalyticsChartsController,
    private readonly userSettingsController: UserSettingsController,
  ) {
    this.productPickerItems$
      .pipe(
        debounceTime(500),
        mergeMap((value) =>
          userSettingsController.updateSetting(
            ANALYTICS_PRODUCT_PICKER_ITEMS_SETTING,
            value,
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe();

    userSettingsController
      .getSetting(ANALYTICS_PRODUCT_PICKER_ITEMS_SETTING)
      .pipe(
        switchMap((setting) =>
          observableWithPending(controller.getProductPickerItems()).pipe(
            map((items) => {
              if ('result' in items) {
                if (setting) {
                  return asOwpSuccess(
                    items.result.map((item) => ({
                      ...item,
                      rows: item.rows.map((row) => ({
                        ...row,
                        icon: `product-${row.name.toLowerCase()}`,
                        checked: !!setting
                          .find((nextItem) => nextItem.title === item.title)
                          ?.rows.find((nextItem) => nextItem.name === row.name)
                          ?.checked,
                      })),
                    })),
                  );
                }
                return asOwpSuccess(
                  items.result.map((item, i) => ({
                    ...item,
                    rows: item.rows.map((row, ii) => ({
                      ...row,
                      icon: `product-${row.name.toLowerCase()}`,
                      checked: i === 0 && ii === 0,
                    })),
                  })),
                );
              }
              return items;
            }),
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe((items) => {
        this.getProductPickerItems$.next(items);
        this.getProductPickerItemsSingle$.next(items);
      });

    observableWithPending(controller.getProductPickerCargoItems())
      .pipe(
        map((items) => {
          if ('result' in items) {
            const nextItems = items.result.slice();
            nextItems[0].rows[0].checked = true;
            return asOwpSuccess(
              nextItems.map((nextItem) => ({
                ...nextItem,
                rows: nextItem.rows.map((row) => ({
                  ...row,
                  icon: `product-reg`,
                })),
              })),
            );
          }
          return items;
        }),
        takeUntil(this._destroy$),
      )
      .subscribe((items) => {
        this.getProductPickerCargoItems$.next(items);
      });

    this.iceFuturesProduct$
      .pipe(
        tap(() => {
          this.updateIceFuturesCurrentItem(0);
          this.updateIceFuturesPeriodItem(this.iceFuturesPeriodItems[0]);
          this.getIceFuturesItems$.next(asOwpSuccess([]));
        }),
        switchMap(({ productId, hubId, specId }) =>
          observableWithPending(
            controller.getIceFuturesItems(productId, hubId, specId),
          ),
        ),
        takeUntil(this._destroy$),
      )
      .subscribe((items) => {
        this.getIceFuturesItems$.next(items);
      });

    observableWithPending(controller.getDynamicsOfProductionVolumesItems())
      .pipe(takeUntil(this._destroy$))
      .subscribe((items) => {
        this.getDynamicsOfProductionVolumesItems$.next(items);
      });

    observableWithPending(
      controller.getDynamicsOfProductionVolumesFourWeeksItems(),
    )
      .pipe(takeUntil(this._destroy$))
      .subscribe((items) => {
        this.getDynamicsOfProductionVolumesFourWeeksItems$.next(items);
      });

    this.updateProductPickerItems$
      .pipe(
        withLatestFrom(this.productPickerItems$),
        map(([item, items]) => {
          return items.map(({ title, rows }) => ({
            title,
            rows:
              title === item.title
                ? item.rows
                : rows.map((row) => ({ ...row, checked: false })),
          }));
        }),
        takeUntil(this._destroy$),
      )
      .subscribe((nextItems) =>
        this.getProductPickerItems$.next(asOwpSuccess(nextItems)),
      );

    this.updateProductPickerItemsSingle$
      .pipe(
        withLatestFrom(this.productPickerItemsSingle$),
        map(([item, items]) => {
          return items.map(({ title, rows }) => ({
            title,
            rows:
              title === item.title
                ? item.rows
                : rows.map((row) => ({ ...row, checked: false })),
          }));
        }),
        takeUntil(this._destroy$),
      )
      .subscribe((nextItems) =>
        this.getProductPickerItemsSingle$.next(asOwpSuccess(nextItems)),
      );

    this.updateProductPickerCargoItems$
      .pipe(
        withLatestFrom(this.productPickerCargoItems$),
        map(([item, items]) => {
          return items.map(({ title, rows }) => ({
            title,
            rows:
              title === item.title
                ? item.rows
                : rows.map((row) => ({ ...row, checked: false })),
          }));
        }),
        takeUntil(this._destroy$),
      )
      .subscribe((nextItems) =>
        this.getProductPickerCargoItems$.next(asOwpSuccess(nextItems)),
      );

    combineLatest([
      this.productGroupNames$,
      this.priceComparisonPeriod$.pipe(debounceTime(500)),
    ])
      .pipe(
        switchMap(([names, { startDate, endDate }]) =>
          observableWithPending(
            controller.getPriceComparisonItems(names, startDate, endDate),
          ),
        ),
        tap((items) => this.getPriceComparisonItems$.next(items)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    combineLatest([
      this.productGroupNames$,
      this.dynamicsOfVolumesPeriod$.pipe(
        debounceTime(500),
        distinctUntilChanged(
          ({ startDate, endDate }, next) =>
            startDate === next.startDate && endDate === next.endDate,
        ),
      ),
    ])
      .pipe(
        switchMap(([names, { startDate, endDate }]) =>
          observableWithPending(
            controller.getDynamicsOfVolumesItems(names, startDate, endDate),
          ),
        ),
        tap((items) => this.getDynamicsOfVolumesItems$.next(items)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    combineLatest([this.productGroupNames$])
      .pipe(
        switchMap(([names]) =>
          observableWithPending(
            controller.getDynamicsOfVolumesItems(
              names,
              this.getStartDate(
                new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 14),
              ),
              this.getEndDate(new Date(new Date().getTime())),
            ),
          ),
        ),
        tap((items) => this.getDynamicsOfVolumesTwoWeeksItems$.next(items)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    combineLatest([
      this.productGroupNames$,
      this.dynamicsByPlantsSessions$.pipe(
        debounceTime(500),
        distinctUntilChanged(
          ({ primary, secondary }, next) =>
            primary === next.primary && secondary === next.secondary,
        ),
      ),
      this.customDateItem$.pipe(
        debounceTime(500),
        distinctUntilChanged(
          ({ startDate, endDate }, next) =>
            startDate === next.startDate && endDate === next.endDate,
        ),
      ),
      this.dynamicsByPlantsDeliveryMethods$.pipe(
        debounceTime(500),
        distinctUntilChanged(
          ({ f, w, u }, next) => f === next.f && w === next.w && u === next.u,
        ),
      ),
    ])
      .pipe(
        switchMap(
          ([
            names,
            { primary, secondary },
            { startDate, endDate },
            { f, w, u },
          ]) =>
            observableWithPending(
              controller.getDynamicsByPlantsItems(
                names,
                startDate,
                endDate,
                primary,
                secondary,
                f,
                w,
                u,
              ),
            ),
        ),
        tap((items) => this.getDynamicsByPlantsItems$.next(items)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    combineLatest([
      this.productGroupNames$,
      this.customDateItem$.pipe(
        debounceTime(500),
        distinctUntilChanged(
          ({ startDate, endDate }, next) =>
            startDate === next.startDate && endDate === next.endDate,
        ),
      ),
      this.dynamicsByBidsSessions$.pipe(
        debounceTime(500),
        distinctUntilChanged(
          ({ primary, secondary }, next) =>
            primary === next.primary && secondary === next.secondary,
        ),
      ),
      this.dynamicsByBidsDeliveryMethods$.pipe(
        debounceTime(500),
        distinctUntilChanged(
          ({ f, w, u }, next) => f === next.f && w === next.w && u === next.u,
        ),
      ),
    ])
      .pipe(
        switchMap(
          ([
            names,
            { startDate, endDate },
            { primary, secondary },
            { f, w, u },
          ]) =>
            observableWithPending(
              controller.getDynamicsByBidsItems(
                names,
                startDate,
                endDate,
                primary,
                secondary,
                f,
                w,
                u,
              ),
            ),
        ),
        tap((items) => this.getDynamicsByBidsItems$.next(items)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    this.productCargoGroupNames$
      .pipe(
        switchMap((names) =>
          observableWithPending(controller.getCargoFlowItems(names)),
        ),
        tap((items) => this.getCargoFlowItems$.next(items)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    combineLatest([
      this.productGroupNames$,
      this.spimexIndexesPeriod$.pipe(
        debounceTime(500),
        distinctUntilChanged(
          ({ startDate, endDate }, next) =>
            startDate === next.startDate && endDate === next.endDate,
        ),
      ),
    ])
      .pipe(
        switchMap(([productGroupNames, periodItem]) =>
          observableWithPending(
            controller.getEtipItems(
              productGroupNames,
              periodItem.startDate,
              periodItem.endDate,
            ),
          ),
        ),
        tap((items) => this.getEtipItems$.next(items)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    combineLatest([
      this.productGroupNames$,
      this.spimexIndexesPeriod$.pipe(
        debounceTime(500),
        distinctUntilChanged(
          ({ startDate, endDate }, next) =>
            startDate === next.startDate && endDate === next.endDate,
        ),
      ),
    ])
      .pipe(
        switchMap(([productGroupNames, periodItem]) =>
          observableWithPending(
            controller.getEripItems(
              productGroupNames,
              periodItem.startDate,
              periodItem.endDate,
            ),
          ),
        ),
        tap((items) => this.getEripItems$.next(items)),
        takeUntil(this._destroy$),
      )
      .subscribe();

    combineLatest([this.iceFuturesPeriodItem$, this.iceFuturesCurrentItem$])
      .pipe(
        withLatestFrom(this.iceFuturesItems$),
        switchMap(
          ([[{ startDate }, iceFuturesCurrentItem], iceFuturesItems]) => {
            if (
              iceFuturesItems[iceFuturesCurrentItem][
                startDate as 'intraday' | '1'
              ].length > 0
            )
              return of(
                asOwpSuccess(
                  iceFuturesItems[iceFuturesCurrentItem][
                    startDate as 'intraday' | '1'
                  ],
                ),
              );
            if (startDate === 'intraday')
              return observableWithPending(
                controller.getIceFuturesIntradayItems(
                  iceFuturesItems[iceFuturesCurrentItem].marketId,
                ),
              );
            return observableWithPending(
              controller.getIceFuturesHistoricalItems(
                iceFuturesItems[iceFuturesCurrentItem].marketId,
                parseInt(startDate, 10),
              ),
            );
          },
        ),
        withLatestFrom(
          this.iceFuturesPeriodItem$,
          this.iceFuturesCurrentItem$,
          this.iceFuturesItems$,
        ),
        map(
          ([
            data,
            iceFuturesPeriodItem,
            iceFuturesCurrentItem,
            iceFuturesItems,
          ]) => {
            if ('result' in data) {
              iceFuturesItems[iceFuturesCurrentItem][
                iceFuturesPeriodItem.startDate as 'intraday' | '1'
              ] = data.result;
              return asOwpSuccess(iceFuturesItems);
            }
            return data;
          },
        ),
        takeUntil(this._destroy$),
      )
      .subscribe((res) => {
        this.getIceFuturesItems$.next(res);
      });
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
