import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  DEALS_DATA_CONFIG,
  DealsDataConfig,
} from '@prlw/data/deals/deals-data.config';
import { DealsGateway } from '@prlw/core/deals/deals.gateway';
import {
  DealResponseData,
  GetAllDealsResponse,
} from '@prlw/data/deals/api-types/get-all-deals.response';
import { GetDealsByInstrumentResponse } from '@prlw/data/deals/api-types/get-deals-by-instrument.response';
import { AllDeal } from '@prlw/core/deals/entities/all-deal.entity';
import { LastDeal } from '@prlw/core/deals/entities/last-deal.entity';
import { MinAndMaxData } from '@prlw/core/deals/entities/min-and-max-data.entity';
import { DealsFilterQuery } from '@prlw/core/deals/deals-filter.provider';
import { GetMaxAndMinPriceResponse } from '@prlw/data/deals/api-types/get-max-and-min-price.api';
import { GetMaxAndMinAmountResponse } from '@prlw/data/deals/api-types/get-max-and-min-amount.api';
import { Product } from '@prlw/core/deals/entities/product.entity';
import { Basis } from '@prlw/core/deals/entities/basis.entity';

@Injectable()
export class HttpDealsGateway implements DealsGateway {
  constructor(
    @Inject(DEALS_DATA_CONFIG) private readonly config: DealsDataConfig,
    private readonly http: HttpClient,
  ) {}

  public getAllDeals(
    sort: string,
    order: string,
    filters: DealsFilterQuery,
    search?: string | null,
  ): Observable<AllDeal[]> {
    const params = {
      sort,
      order: order.toLowerCase(),
      ...filters,
      ...(search && { search }),
    };
    return this.http
      .get<GetAllDealsResponse>(`${this.config.apiPrefix}/all-deals`, {
        params,
        headers: { 'Pagination-key': 'all-deals', 'Pagination-size': '100' },
      })
      .pipe(
        map((response) => this._mapAllDeals(response.deals)),
        catchError((err) => {
          throw err;
        }),
      );
  }

  public getDealsByInstrument(
    instrumentCode: string,
    sort: string,
    order: string,
  ): Observable<LastDeal[]> {
    const params = {
      sort,
      order: order.toUpperCase(),
    };
    return this.http
      .get<GetDealsByInstrumentResponse>(
        `${this.config.apiPrefix}/all/${instrumentCode}`,
        {
          params,
        },
      )
      .pipe(map((response) => this._mapDealsByInstrument(response)));
  }

  public getProducts(): Observable<Product[]> {
    return this.http.get<Product[]>(
      `${this.config.apiPrefix}/all-deals/products`,
    );
  }

  public getBases(): Observable<Basis[]> {
    return this.http.get<Basis[]>(`${this.config.apiPrefix}/all-deals/bases`);
  }

  public getMaxAndMinAmount(): Observable<MinAndMaxData> {
    return this.http
      .get<GetMaxAndMinAmountResponse>(
        `${this.config.apiPrefix}/all-deals/amount`,
      )
      .pipe(map((data) => ({ min: data.minAmount, max: data.maxAmount })));
  }

  public getMaxAndMinPrice(): Observable<MinAndMaxData> {
    return this.http
      .get<GetMaxAndMinPriceResponse>(
        `${this.config.apiPrefix}/all-deals/price`,
      )
      .pipe(map((data) => ({ min: data.minPrice, max: data.maxPrice })));
  }

  private _mapAllDeals(deals: DealResponseData[] | undefined): AllDeal[] {
    return (
      deals?.map((deal) => ({
        dealId: deal.dealId,
        instrumentCode: deal.instrumentCode,
        price: deal.price,
        totalPrice: deal.totalPrice,
        volume: deal.amount,
        basisCode: deal.shipmentBasis,
        timestamp: deal.timestamp,
        productGroup: deal.productGroup,
        productName: deal.productName,
        shipmentBasis: deal.shipmentBasis,
        shipmentBasisName: deal.shipmentBasisName,
        shipmentBasisPlants: deal.shipmentBasisPlants,
        shipmentBasisStations: deal.shipmentBasisStations,
      })) ?? []
    );
  }

  private _mapDealsByInstrument(
    deals: DealResponseData[] | undefined,
  ): LastDeal[] {
    return (
      deals?.map((deal) => ({
        dealId: deal.dealId,
        instrumentCode: deal.instrumentCode,
        price: deal.price,
        volume: deal.amount,
        basisCode: deal.shipmentBasis,
        timestamp: deal.timestamp,
        productGroup: deal.productCode,
      })) ?? []
    );
  }
}
