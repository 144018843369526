import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const TARIFFS_GATEWAY = new InjectionToken<TariffsGateway>(
  'tariffs-gateway',
);

export interface TariffsGateway {
  updateTraderTariff(params: {
    productId: number;
    shipmentBasisId: number;
    destinationStationId: number;
    price: number;
    changeSimilarProductsTariff: boolean;
  }): Observable<string>;

  deleteTraderTariff(params: {
    productId: number;
    shipmentBasisId: number;
    destinationStationId: number;
    changeSimilarProductsTariff: boolean;
  }): Observable<string>;

  updateWaitStatusTariff(
    params: {
      instrumentCode: string;
      productId: number;
      shipmentBasisId: number;
      destinationStationId: number;
    }[],
  ): Observable<string>;
}
